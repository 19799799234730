
    .student-wrapper {
        .header {
            display: flex;
            width: 100%;
            height: 60px;
            background: #fff;
            position: relative;
            align-items: center;

            .train-manage {
                border: 1px solid #FD4446;
                padding: 10px 11px;
                position: absolute;
                left: 60px;
                /*top: 22px;*/
                color: #FD4446;

                &:hover {
                    color: #fd4446d9;
                    background-color: #fff;
                    border: 1px solid #fd4446d9;
                }
            }

            .header-left {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 1%;
                flex: 1;
                color: #FD4446;
                font-weight: 500;
                font-size: 18px;
            }

            .header-right {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-right: 30px;

                .user-info {
                    display: flex;
                    align-items: center;

                    .user-avatar {
                        width: 32px;
                        height: 32px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        overflow: hidden;
                        border-radius: 50%;
                        border: 1px solid #eee;
                        box-sizing: border-box;

                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }

                    .user-name {
                        margin-left: 6px;
                    }
                }

                .exit-btn {
                    margin-left: 24px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    transition: all .3s;
                    height: 24px;

                    .iconfont {
                        font-size: 24px;
                        color: #1122D8;
                    }

                    div {
                        height: 24px;
                        line-height: 30px;
                    }

                    &:hover {
                        color: #FD4446;
                    }
                }
            }
        }

        .container {
            width: 100%;
            height: calc(100vh - 60px);
            background: url("../../assets/images/studentIndex/index-bg.png") no-repeat bottom center, linear-gradient(#04BEFE, #4482EB);
            background-size: cover;
            position: relative;

            /deep/ .el-scrollbar__wrap {
                overflow-x: hidden;

                .el-scrollbar__view {
                    min-width: 800px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                }
            }

            .box-one {
                width: 100%;
                max-width: 1200px;
                margin: 0 auto;
                height: calc(100vh - 300px);
                background: #fff;
                border-radius: 20px;
                display: flex;

                .one-item {
                    &.one-left {
                        width: 56.6666%;
                        border-radius: 20px 0 0 20px;
                        background-color: #EDFDFF;
                        background-repeat: no-repeat;
                        background-position: bottom center;
                        /*background-size: 90% auto;*/
                        background-size: auto 65%;

                        .left-title {
                            color: #3096F2;
                            font-size: 36px;
                            text-align: center;
                            margin-top: 8%;
                        }
                    }

                    &.one-content {
                        width: 1%;
                        flex: 1;
                        padding: 0 70px 0 40px;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;

                        .content-title {
                            font-size: 24px;
                            color: #343434;
                        }

                        .content-introduce {
                            margin: 25px 0 40px;
                            color: #666;
                            font-size: 18px;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 10;
                            overflow: hidden;
                        }
                    }
                }

                &.not-allowed {
                    .one-item {
                        background-image: none !important;

                        &.one-left {
                            .left-title {
                                color: #999;
                            }

                            .no-begin {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                flex-direction: column;
                                padding: 0 20px;

                                img {
                                    max-width: 100%;
                                    margin-top: 20px;
                                }

                                .prompt {
                                    margin-top: 20px;
                                    text-align: center;
                                    font-size: 16px;
                                    color: #999;
                                }
                            }
                        }

                        &.one-content {
                            .wrapper {
                                a {
                                    pointer-events: none;
                                    cursor: not-allowed;
                                }
                            }
                        }
                    }
                }
            }

            .multiple-content {
                width: 100%;
                max-width: 1200px;
                margin: 0 auto;
                height: 100%;
                display: flex;
                flex-direction: column;

                .multiple-title {
                    text-align: center;
                    font-size: 36px;
                    color: #fff;
                    margin-top: 60px;
                    font-weight: 500;
                    line-height: 1;
                }

                .multiple-introduce {
                    font-size: 18px;
                    margin-top: 30px;
                    line-height: 30px;
                    color: #fff;
                    height: 90px;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                    overflow: hidden;
                }

                .multiple-box {
                    height: 1%;
                    flex: 1;
                    margin: 0 0 60px;
                    display: flex;
                    /*max-height: 450px;*/
                    .mul-item {
                      margin-top: 20px;
                        border-radius: 20px;
                        background-color: #EDFDFF;
                        background-repeat: no-repeat;
                        background-position: bottom center;
                        /*background-size: 90% auto;*/
                        background-image: url("../../assets/images/studentIndex/zhuangxiu.png");
                        /*position: relative;*/
                        .mul-title {
                            color: #2F95F1;
                            font-size: 30px;
                            text-align: center;
                            margin-top: 8%;
                        }
                    }

                    &.not-allowed {
                        .mul-item {
                            pointer-events: none;
                            cursor: not-allowed;
                            background-image: none !important;

                            .mul-title {
                                color: #999;
                            }

                            .no-begin {
                                padding: 0 20px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;

                                img {
                                    display: block;
                                    max-width: 100%;
                                    margin-top: 40px;
                                }

                                .prompt {
                                    margin-top: 20px;
                                    text-align: center;
                                    font-size: 16px;
                                    color: #999;
                                }
                            }
                        }
                    }

                    &.box-two {
                        .mul-item {
                            /*width: 34%;*/
                            /*margin: 0 8%;*/
                            /*background-size: auto 63%;*/
                            /*width: calc(50% - 60px);*/
                            width: 40%;
                            margin: 0 5%;
                            background-size: auto 60%;
                        }

                        &.not-allowed {
                            .mul-item {
                                .no-begin {
                                    img {
                                        max-width: 70%;
                                    }
                                }
                            }
                        }
                    }

                    &.box-three {
                        margin-left: -60px;

                        .mul-item {
                            width: calc(33.333333% - 60px);
                            margin-left: 60px;
                            background-size: auto 60%;

                            .mul-title {
                                margin-top: 12%;
                                font-size: 28px;
                            }
                        }
                    }

                    &.box-four {
                        margin-left: -30px;

                        .mul-item {
                            width: calc(25% - 30px);
                            margin-left: 30px;
                            /*background-size: auto 55%;*/
                            background-size: 100% auto;

                            .mul-title {
                                font-size: 22px;
                                margin-top: 30%;
                            }
                        }
                    }


                    &.box-five {
                        margin-left: -20px;

                        .mul-item {
                            width: calc(20%);
                            margin-left: 20px;
                            /*background-size: auto 55%;*/
                            background-size: 100% auto;

                            .mul-title {
                                font-size: 20px;
                                margin-top: 32%;
                            }
                        }
                    }


                    &.box-six {
                        margin-left: -20px;
                        flex-wrap: wrap;
                        .mul-item {
                            width: calc(20% - 20px);
                            margin-left: 20px;
                            /*background-size: auto 55%;*/
                            background-size: 100% auto;

                            .mul-title {
                                font-size: 18px;
                                margin-top: 20px;
                            }
                        }
                    }
                }
            }
        }
    }

    /deep/ .end-dialog {
        .el-dialog__header {
            border-bottom: 1px solid #eee;
        }

        .el-dialog__headerbtn:focus .el-dialog__close,
        .el-dialog__headerbtn:hover .el-dialog__close {
            color: #FD4446;
        }

        .end-competition {
            text-align: center;

            img {
                height: 170px;
            }

            .text {
                margin-top: 28px;
                color: #666;
                font-size: 18px;
            }
        }
    }
